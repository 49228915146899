import React from 'react'
import { Link } from 'gatsby'

import PageHeader from '@c/page-header'
import Icon from '@c/icon'
import ProjectImage from '@/components/project-image'

const Hero = ({
  title,
  description,
  button1Text,
  button1Url,
  button2Text,
  button2Url,
  image
}) => {
  return (
    <div className="container ta-center ta-lg-left">
      <div className="row">
        {/* content */}
        <div className="col-12 col-lg-5 order-1 order-lg-0 mt-40 mt-lg-0">
          <PageHeader type="h2" title={title} />

          <p className="mt-32">{description}</p>

          <div className="d-flex flex-column flex-lg-row align-items-center mt-40 mt-lg-60">
            <Link to={button1Url} className="btn btn--round btn--red">
              {button1Text}
            </Link>
            <Link to={button2Url} className="link-hover mt-24 mt-lg-0 ml-lg-32">
              {button2Text}
              <Icon name="right" className="ml-8" />
            </Link>
          </div>
        </div>

        {/* photo */}
        <div className="col-12 col-lg-6 offset-lg-1 mt-lg-n120">
          <ProjectImage className="tools-detail-hero-photo" image={image} />
        </div>
      </div>
    </div>
  )
}

export default Hero
