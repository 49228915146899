import React, { useMemo } from 'react'

import PageHeader from '@c/page-header'
import ProjectImage from '@/components/project-image'

const Info = ({
  titleSmall,
  titleBig,
  description,
  features,
  imageFront,
  imageBack
}) => {
  const featuresArr = useMemo(() => (features ? JSON.parse(features) : null), [
    features
  ])

  return (
    <div className="container mt-80 mt-lg-160">
      <div className="row align-items-center">
        {/* content */}
        <div className="col-12 col-lg-5">
          <PageHeader type="h3" topTitle={titleSmall} title={titleBig} />

          <div
            className="mt-32"
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>

          <ul className="list-with-icon mt-60">
            {featuresArr &&
              featuresArr.map((feature, i) => (
                <li key={i} className="mt-24">
                  {feature}
                </li>
              ))}
          </ul>
        </div>

        {/* photo */}
        <div className="col-12 col-lg-6 offset-lg-1 d-none d-lg-block">
          <figure className="tools-detail-mobile">
            {imageBack && (
              <ProjectImage
                className="tools-detail-mobile-back"
                image={imageBack}
              />
            )}
            {imageFront && (
              <ProjectImage
                className="tools-detail-mobile-front"
                image={imageFront}
              />
            )}
          </figure>
        </div>
      </div>
    </div>
  )
}

export default Info
