import React from 'react'
import { graphql } from 'gatsby'
import DatoCMSModel from '../../models/DatoCMSModel'

import Layout from '@c/layout'
import Breadcrumb from '@c/breadcrumb'

import Hero from '@v/tools-detail/hero'
import Info from '@v/tools-detail/info'

import makeSlug from '@u/make-slug'

const Page = ({ data }) => {
  const { tool, toolsPage } = data
  const parentPages = [
    {
      title: toolsPage.title,
      url: makeSlug.generic(toolsPage.locale, toolsPage.slug)
    }
  ]
  const { title } = tool
  const model = new DatoCMSModel(tool)

  return (
    <Layout
      locale={tool.locale}
      seo={{ ...model.getSeoComponentProps(toolsPage._allSlugLocales) }}
    >
      {/* BREADCRUMB */}
      <div className="container mb-60">
        <div className="row">
          <div className="col-12">
            <Breadcrumb parentPages={parentPages} currentPage={title} />
          </div>
        </div>
      </div>

      <Hero {...tool.hero[0]} />

      <Info {...tool.info[0]} />
    </Layout>
  )
}

export default Page

export const query = graphql`
  query ToolDetailsQuery($id: String, $locale: String) {
    toolsPage: datoCmsToolsPage(locale: { eq: $locale }) {
      _allSlugLocales {
        locale
        value
      }
      metaTags {
        ...MetaTags
      }
      locale
      slug
      title
    }

    tool: datoCmsTool(id: { eq: $id }) {
      _allSlugLocales {
        locale
        value
      }
      locale
      slug
      title

      hero {
        title
        description
        button1Text
        button1Url
        button2Text
        button2Url
        image {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }

      info {
        titleSmall
        titleBig
        description
        features
        imageFront {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }

        imageBack {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`
